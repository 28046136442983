import logo from './logo.svg';
import './App.css';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Home from './components/home/Home'
import { Route, Routes } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import Cursor from './components/Curser';
import UperFooter from './components/UperFooter';

function App() {
  return (
    <div className="App">
       <Navbar/> 
 
        <ScrollToTop/>
        <Routes>
          <Route path='/' element={<Home/>}/> 
        </Routes> 
        <UperFooter/>
        <Footer/>
    </div>
  );
}

export default App;
