import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Footer = () => { 
  const [scrollPosition, setScrollPosition] = useState(0); 
  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {  
    window.addEventListener('scroll', handleScroll); 
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navigate = useNavigate()

  const location = useLocation();
const isActiveLink = (link) => {
  return link === location.pathname;
};



  

  return (
    <div>
     

      <div style={{background:'rgba(0,0,0,0.2)'}}>
        <br />
        <div class='col-11 m-auto text-start'>   
          <div class='my-4' style={{background:'#8AD04D',width:'100%',height:'2px'}}></div>
          
          <div class='row mt-5'>
            <div class='col-md-5'>
              <div>
                <img class='hiLob-lovo' src={require('../image/logo.webp')}  alt="" />
                <p class='text-white fw-bold mt-3'>+91 123 456 7890</p>
                <p class='text-white fw-bold'>Hilabsolution@gmail.com</p> 
              </div>
            </div>
            <div class='col-md-7'>
              <div class='d-flex flex-wrap text-white mt-2 '>
                  <p class='fw-bold sliderBtn' style={isActiveLink('/') ? {marginRight:'20px',color:'#8AD04D'}:{marginRight:'20px',color:'white'}} onClick={()=> navigate('./')}>Home</p>
                  <p class='fw-bold sliderBtn' style={isActiveLink('/about') ? {marginRight:'20px',color:'#8AD04D'}:{marginRight:'20px',color:'white'}} onClick={()=> navigate('./about')}>About Us</p>
                  <p class='fw-bold sliderBtn' style={isActiveLink('/location') ? {marginRight:'20px',color:'#8AD04D'}:{marginRight:'20px',color:'white'}} onClick={()=> navigate('./location')}>Locations</p> 
                  <p class='fw-bold sliderBtn' style={isActiveLink('/contact') ? {marginRight:'20px',color:'#8AD04D'}:{marginRight:'20px',color:'white'}} onClick={()=> navigate('./contact')}>Contact Us</p> 
              </div>
            </div>
          </div>

          <div class='row mt-4'>
            <div class='col-md-5'>
              <div>  
                <p class='text-white' style={{fontSize:'14px'}}>Hi-lab Solution © 2020. All rights reserved.</p> 
              </div>
            </div>
            <div class='col-md-7'>
              <p class='text-white' style={{fontSize:'14px'}}>Privacy Policy | erms and conditions</p> 
            </div>
          </div> 
        </div>
        <br />
      </div>
    </div>
  );
};

export default Footer;
