import React, { useState } from 'react';
import './service.css'
const servic = [
  {
    title: 'Web Design',
    img : `${require('../../image/serviceBg.jpg')}`
  },
  {
    title: 'Product Design and Development',
    img : `${require('../../image/serviceBg.jpg')}` 
  },
  {
    title: 'SaaS Project',
    img : `${require('../../image/serviceBg.jpg')}` 
  },
  {
    title: 'Mobile App Development',
    img : `${require('../../image/serviceBg.jpg')}` 
  },
  {
    title: 'Branding and Logo Design',
    img : `${require('../../image/serviceBg.jpg')}` 
  },
  {
    title: 'Social media post design',
    img : `${require('../../image/serviceBg.jpg')}` 
  },
];

const output2 = (el) => {
  return (
    Array(10).fill().map((_, index) => (
      <span key={index}>{index > 0 ? ' . ' : ''}{el}</span>
    ))
  );
};



const Service = () => {

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => { 
    setMousePosition({ x: e.clientX, y: e.clientY });
  };


  const services = () => {
    let arr = [];
    for (let i = 0; i < servic.length; i++) {
      arr.push(
        <div style={{ borderTop: '1px solid white' }} key={i} onMouseMove={handleMouseMove}>
          <div className='col-11 m-auto services'>
              <div class='servicText1 '>  
                  <p className='fs-5 fw-bold text-white text-start mt-2'>{servic[i].title}</p> 
              </div>
              <div class="servicText2" > 
                  <p className='fs-5 fw-bold text-start   '>{output2(`${servic[i].title}`)}</p>
              </div>
              <div class='serviceImg' style={{left:`${mousePosition.x-100}px`}}>
                  <img src={servic[i].img} style={{width:'100%'}} alt="" />
              </div>
          </div>
        </div>
      );
    }
    return arr;
  };
  return (
    <div>
      <div>
        {services()}
      </div>
    </div>
  );
};

export default Service;
