import React, { useEffect, useState } from 'react'
import './herotext2.css'

const HeroText2 = () => {

  const [imgAxis, setImgAxis] = useState()
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });


    
  const updateWindowSize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowSize);
    window.addEventListener('load', updateWindowSize);

    return () => {
      window.removeEventListener('resize', updateWindowSize);
      window.removeEventListener('load', updateWindowSize);
    };
  }, []);
  console.log(windowSize);

  useEffect(()=>{
    setImgAxis(windowSize.width)
  },[windowSize])



  useEffect(()=>{ 
    document.querySelector('#overlay1').addEventListener("mousemove", (e)=>{
      document.querySelector('#elem1 img').style.scale = 1   
      document.querySelector('#elem1 img').style.opacity = 1 
      document.querySelector('#elem1 img').style.left = (e.x-(imgAxis < 1550 ? 650 : 1000)) + "px"
      document.querySelector('#elem1 img').style.top = (e.y-(imgAxis < 1550 ? 250 : 400)) + "px" 
    })
    document.querySelector('#overlay1').addEventListener("mouseleave", (e)=>{
      document.querySelector('#elem1 img').style.scale = 0
      document.querySelector('#elem1 img').style.opacity = 0  
    }) 

    document.querySelector('#overlay2').addEventListener("mousemove", (e)=>{
      document.querySelector('#elem2 img').style.scale = 1   
      document.querySelector('#elem2 img').style.opacity = 1 
      document.querySelector('#elem2 img').style.left = (e.x-(imgAxis < 1550 ? 650 : 1000)) + "px"
      document.querySelector('#elem2 img').style.top = (e.y-(imgAxis < 1550 ? 420 : 550)) + "px" 
    })
    document.querySelector('#overlay2').addEventListener("mouseleave", (e)=>{
      document.querySelector('#elem2 img').style.scale = 0
      document.querySelector('#elem2 img').style.opacity = 0  
    }) 

    document.querySelector('#overlay3').addEventListener("mousemove", (e)=>{
      document.querySelector('#elem3 img').style.scale = 1   
      document.querySelector('#elem3 img').style.opacity = 1 
      document.querySelector('#elem3 img').style.left = (e.x-(imgAxis < 1550 ? 650 : 1000)) + "px"
      document.querySelector('#elem3 img').style.top = (e.y-(imgAxis < 1550 ? 550 : 700)) + "px" 
    })
    document.querySelector('#overlay3').addEventListener("mouseleave", (e)=>{
      document.querySelector('#elem3 img').style.scale = 0
      document.querySelector('#elem3 img').style.opacity = 0  
    }) 
  })
  return (
    <div class='herotext2  '>
       <div id='main1'>
        <div id='cursor1'></div>
        <div class='elem' id='elem1'>
          <p>Empathy</p>
          <img src={require('../../image/cursor.jpg')} alt="" />
          <div id='overlay1' class='overlay'></div>
        </div> 

        <div class='elem' id='elem2'>
          <p>Visualize</p>
          <img src={require('../../image/cursor.jpg')} alt="" />
          <div id='overlay2' class='overlay'></div>
        </div>   

        <div class='elem' id='elem3'>
          <p>Interact</p>
          <img src={require('../../image/cursor.jpg')} alt="" />
          <div id='overlay3' class='overlay'></div>
        </div>  

        <div class='text-start'>
          <button class='button  text-white  mt-4 fs-5'  >Let's Talk</button>
        </div>

       </div>



 
    </div>
  )
}

export default HeroText2