import React from 'react'

const UperFooter = () => {
  return (
    <div style={{background:'#F6FFEE'}}>
        <br />
        <div class='col-11 m-auto my-4'>
            <p class='titleText col-lg-8 text-start'>LIKE WHAT YOU SEE?</p>
            <div class='row text-start '>
                <div class='col-md-6 mt-3'>
                    <p class='fs-4 fw-bold'>Get in Touch and Let's build something great together</p>
                </div>
                <div class='col-md-6 mt-3' >
                    <button class='button  text-white '  >Let's Talk</button>

                </div> 
            </div>
        </div>
        <br />
    </div>
  )
}

export default UperFooter