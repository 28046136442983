import React, { useEffect, useState } from 'react'
import './style.css'
import { useLocation, useNavigate } from 'react-router-dom';
// import { IoSunnySharp } from "re";


const Navbar = () => { 
  const [dark, setDark] = useState(false)
  const [isActive, setIsActive] = useState(false);


  useEffect(()=>{
    const nav = document.querySelector('.mainNavbar');
    let lastScrollY = window.scrollY; 
    window.addEventListener('scroll',()=>{
        if(window.scrollY > 50){
             nav.classList.add("navColor"); 
        }else{
           nav.classList.remove("navColor");  
        }
    })
    window.addEventListener('scroll',()=>{
      if(lastScrollY < window.scrollY){
        nav.classList.add('mainNavbar--hidden');
      }else{
        nav.classList.remove('mainNavbar--hidden');
      }  
      lastScrollY =window.scrollY

    })  
})

useEffect(()=>{
  const nav = document.querySelector('.menus')
  nav.style.transition ="0.5s"
  nav.style.clipPath = 'circle(0% at 100% 0%)'
  if(isActive){
  nav.style.transition ="1s" 
  nav.style.clipPath = 'circle(150% at 100% 0%)' 
  } 
},[isActive])

const navigate = useNavigate()

const chengRouts =(el)=>{
   setIsActive(false)
   navigate(el) 
}

const location = useLocation();
const isActiveLink = (link) => {
  return link === location.pathname;
};

  return (
    <div class='mainNavbar'>
        <div class='d-flex align-items-center h-100 '  >
          <div class='col-11 m-auto d-flex align-items-center justify-content-between p-1'> 
            <div>
              <img class='hiLob-lovo' onClick={()=>navigate('./')} src={require('../image/logo.webp')}  alt="" />
            </div>
            <div class='d-flex align-items-center gap-5'>
              <div class='pcNav'> 
                <div class='d-flex align-items-center gap-4'>
                  <p class='fw-bold' style={isActiveLink('/') ? {cursor:'pointer',color:'#8AD04D'}:{cursor:'pointer',color:'white'}} onClick={()=>navigate('./')}>Home</p>
                  <p class='fw-bold' style={isActiveLink('/about') ? {cursor:'pointer',color:'#8AD04D'}:{cursor:'pointer',color:'white'}} onClick={()=>navigate('./about')}>About Us</p>
                  <p class='fw-bold' style={isActiveLink('/location') ? {cursor:'pointer',color:'#8AD04D'}:{cursor:'pointer',color:'white'}} onClick={()=>navigate('./location')}>Locations</p>
                  <p class='fw-bold' style={isActiveLink('/contact') ? {cursor:'pointer',color:'#8AD04D'}:{cursor:'pointer',color:'white'}} onClick={()=>navigate('./contact')}>Contact Us</p>

                </div>
              </div>
              <div class='d-flex align-items-center gap-sm-5 gap-3'>
                <button class='button  text-white '  >Let's Talk</button>
                <div class='lightDark2' style={dark?{marginRight:'10px'}:{marginRight:'10px'}} onClick={()=>setDark(!dark)}>
                    <div class={dark ?'lightDarkInner2 modeMove':'lightDarkInner2 '}>
                      <div style={{position:'relative'}}>
                        <p style={dark ?{opacity:1,transition:'1s',color:'#8AD04D'}:{opacity:0,transition:'1s',color:'#8AD04D'}} class='fs-5'><i class="bi bi-moon-fill "></i></p>
                        <p style={dark ?{opacity:0,transition:'1s',color:'#8AD04D',position:'absolute',top:0}:{opacity:1,transition:'1s',color:'#8AD04D',position:'absolute',top:0}} class='fs-5'><i class="bi bi-brightness-high-fill "></i></p> 
                      </div> 
                    </div>
                </div>
                {/* hambargar */}
                <div class='ham'>
                    <div class={isActive ? 'hamburger is-active' : 'hamburger'} id="hamburger" onClick={()=> setIsActive(!isActive)}>
                      <span class="line"></span>
                      <span class="line"></span>
                      <span class="line"></span>
                    </div> 
                </div>
                {/* hambargar */}

              </div>
            </div>
          </div>
        </div>
        <div class='menus d-flex justify-content-center align-items-center' style={{width:'100%',height:'100vh',position:'fixed',backgroundImage:'linear-gradient(to right, #46D480, #001AFF)',top:'0',right:'0',overflowX:'hidden',zIndex:'-1'}}>
            <div class='d-grid justify-content-center  align-items-center' style={{height:'100%'}}>
                  <div class='d-grid gap-3'> 
                    <p class='fs-1 playfair fw-semibold' style={isActiveLink('/') ? {cursor:'pointer',color:'#8AD04D'}:{cursor:'pointer',color:'white'}} onClick={()=>chengRouts('./')}>Home</p>
                    <p class='fs-1 playfair fw-semibold' style={isActiveLink('/about') ? {cursor:'pointer',color:'#8AD04D'}:{cursor:'pointer',color:'white'}} onClick={()=>chengRouts('./about')}>About Us</p>
                    <p class='fs-1 playfair fw-semibold' style={isActiveLink('/location') ? {cursor:'pointer',color:'#8AD04D'}:{cursor:'pointer',color:'white'}} onClick={()=>chengRouts('./location')}>Locations</p> 
                    <p class='fs-1 playfair fw-semibold' style={isActiveLink('/contact') ? {cursor:'pointer',color:'#8AD04D'}:{cursor:'pointer',color:'white'}} onClick={()=>chengRouts('./contact')}>Contact Us</p>

                  </div> 
            </div> 
        </div>

       
    </div>
  )
}

export default Navbar