import React, { useEffect, useState } from 'react'
import './projectSlider.css'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
const projects = [
    {
        type: 'Web Design',
        title: 'Plastic Innovation',
        text: 'Lorem ipsum dolor sit amet, consectetuLorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        img: `${require('../../image/projects/p1.png')}`
    },
    {
        type: 'Web Design',
        title: 'ARIS',
        text: 'Lorem ipsum dolor sit amet, consectetuLorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        img: `${require('../../image/projects/p2.png')}`
    },
    {
        type: 'Web Design',
        title: 'Chiku',
        text: 'Lorem ipsum dolor sit amet, consectetuLorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        img: `${require('../../image/projects/p3.png')}`
    },
    {
        type: 'App Design',
        title: 'Chiku',
        text: 'Lorem ipsum dolor sit amet, consectetuLorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        img: `${require('../../image/projects/p3.png')}`
    },
    {
        type: 'App Design',
        title: 'Plastic Innovation',
        text: 'Lorem ipsum dolor sit amet, consectetuLorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        img: `${require('../../image/projects/p1.png')}`
    },
    {
        type: 'App Design',
        title: 'ARIS',
        text: 'Lorem ipsum dolor sit amet, consectetuLorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        img: `${require('../../image/projects/p2.png')}`
    }, 
    {
        type: 'Dashboard',
        title: 'ARIS',
        text: 'Lorem ipsum dolor sit amet, consectetuLorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        img: `${require('../../image/projects/p2.png')}`
    }, 
    {
        type: 'Dashboard',
        title: 'Plastic Innovation',
        text: 'Lorem ipsum dolor sit amet, consectetuLorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        img: `${require('../../image/projects/p1.png')}`
    }, 
    {
        type: 'Dashboard',
        title: 'Chiku',
        text: 'Lorem ipsum dolor sit amet, consectetuLorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        img: `${require('../../image/projects/p3.png')}`
    },
    {
        type: 'E-Commerce',
        title: 'Chiku',
        text: 'Lorem ipsum dolor sit amet, consectetuLorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        img: `${require('../../image/projects/p3.png')}`
    }, 
    {
        type: 'E-Commerce',
        title: 'Plastic Innovation',
        text: 'Lorem ipsum dolor sit amet, consectetuLorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        img: `${require('../../image/projects/p1.png')}`
    },
    {
        type: 'E-Commerce',
        title: 'ARIS',
        text: 'Lorem ipsum dolor sit amet, consectetuLorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur',
        img: `${require('../../image/projects/p2.png')}`
    }, 
]

const ProjectSlider = () => {
    const [data, setData] = useState(projects)
    const [showProject, setShowProject] = useState()
    const [project,setProject] = useState('Web Design')


    useEffect(()=>{ 
        setShowProject(data.filter((el) => el.type == project))
    },[project]) 

    console.log(project);
    console.log(showProject);

    
    return (
        <div class='projectSlider col-11 m-auto'>
            <div class='d-flex align-items-center gap-4 justify-content-start justify-content-md-end' style={{overflowX:'auto'}}>
                <p class='text-nowrap' style={project == 'Web Design' ? {color:'black',fontWeight:'600',textDecoration:'underline',cursor:'pointer',transition:'1s'}:{color:'#A2BC8B',fontWeight:'600',cursor:'pointer',transition:'1s'}} onClick={()=>setProject('Web Design')}>Web Design</p>
                <p class='text-nowrap' style={project == 'App Design' ? {color:'black',fontWeight:'600',textDecoration:'underline',cursor:'pointer',transition:'1s'}:{color:'#A2BC8B',fontWeight:'600',cursor:'pointer',transition:'1s'}} onClick={()=>setProject('App Design')}>App Design</p>
                <p class='text-nowrap' style={project == 'Dashboard' ? {color:'black',fontWeight:'600',textDecoration:'underline',cursor:'pointer',transition:'1s'}:{color:'#A2BC8B',fontWeight:'600',cursor:'pointer',transition:'1s'}} onClick={()=>setProject('Dashboard')}>Dashboard</p>
                <p class='text-nowrap' style={project == 'E-Commerce' ? {color:'black',fontWeight:'600',textDecoration:'underline',cursor:'pointer',transition:'1s'}:{color:'#A2BC8B',fontWeight:'600',cursor:'pointer',transition:'1s'}} onClick={()=>setProject('E-Commerce')}>E-Commerce</p>
            </div>

            <div class='mt-4'> 
            <Carousel  
                autoPlay={false}
                arrows={true}
                autoPlaySpeed={2500}
                showDots={false}
                axis='vertical'
            >
                {showProject?.map((el) => {
                    return (
                        <div>
                            <br />
                                <div class='col-11 m-auto row'>
                                <div class='col-md-6'>
                                    <div class='col-11 m-auto' style={{borderRadius:'300px'}}>
                                        <img src={el.img} style={{height:'350px',width:'100%',objectFit:'contain',borderRadius:'300px'}} alt="" />
                                    </div>
                                </div>
                                <div class='col-md-6 '>
                                    <div class='col-11 m-auto text-start'>
                                        <p class='fs-1'>{el.title}</p>
                                        <p class='my-3'>{el.text}</p>
                                        <button class='button  text-white mt-2 '  >Let's Talk</button>

                                    </div>
                                </div> 
                                </div>
                            <br />
                        </div>
                    );
                })}
            </Carousel>
            </div>
        </div>
    )
}

export default ProjectSlider