import React, { useEffect, useRef, useState } from 'react';
import '../style.css'; 
import AOS from "aos";
import "aos/dist/aos.css";
import HomeScroll from './HomeScroll'; 
import customCursorImage from '../../image/homebgB.jpg';
import ProjectSlider from './ProjectSlider';
import HeroText2 from './HeroText2';
import VerticalCarousel from './VerticalCarousel';
import Service from './Service';

const Home = () => { 
  const videoRef = useRef(null);
  const [hasIntersected, setHasIntersected] = useState(false);
  
  useEffect(() => {
    AOS.init({ duration: 2000 });
  },[]);

  useEffect(() => {
    const videoObserver = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust the threshold as needed
    }); 
    videoObserver.observe(videoRef.current); 
    return () => {
      videoObserver.disconnect();
    };
  }, []);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && !hasIntersected) {
        setHasIntersected(true);
        playVideo();
      }
    });
  };

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play().then(() => { 
      }).catch((error) => { 
        console.error('Autoplay failed:', error);
      });
    }
  };

  // useEffect(()=>{
  //   AOS.init({duration: 1500});   
  //   const text1 = document.querySelector("#elem1")
  //   const text2 = document.querySelector("#elem2")
  //   const text3 = document.querySelector("#elem3") 
  //   text1.addEventListener('mousemove', (e)=>{ 
  //     let text1Width = text1.clientWidth
  //     let text1Height = text1.clientHeight 
  //     let moveX1 = (e.offsetX-text1Width)/5
  //     let moveY1 = (e.offsetY-text1Height)/5
  //     text1.style.transform = `translateX(${moveX1}px) translateY(${moveY1}px)`
  //     console.log(`translateX(${moveX1}px) translateY(${moveY1}px)`);  
  //   }); 
  //   text1.addEventListener('mouseout', (e)=>{
  //     text1.style.transform = '' 
  //   })

  //   text2.addEventListener('mousemove', (e)=>{ 
  //     let text2Width = text2.clientWidth
  //     let text2Height = text2.clientHeight 
  //     let moveX2 = (e.offsetX-text2Width)/5
  //     let moveY2 = (e.offsetY-text2Height)/5
  //     text2.style.transform = `translateX(${moveX2}px) translateY(${moveY2}px)` 
  //   }); 
  //   text2.addEventListener('mouseout', (e)=>{
  //     text2.style.transform = '' 
  //   })

  //   text3.addEventListener('mousemove', (e)=>{ 
  //     let text3Width = text3.clientWidth
  //     let text3Height = text3.clientHeight 
  //     let moveX3 = (e.offsetX-text3Width)/5
  //     let moveY3 = (e.offsetY-text3Height)/5
  //     text3.style.transform = `translateX(${moveX3}px) translateY(${moveY3}px)` 
  //   }); 
  //   text3.addEventListener('mouseout', (e)=>{
  //     text3.style.transform = '' 
  //   })
  // })


  return (
    <div>
    <div style={{position:'relative'}}>
      <div class='mdShow'></div>
      <div class='homehead'>
        <div class='homeheada'> 
            <video ref={videoRef} autoPlay={false} controls={false} playsInline muted  className="col-12 m-auto" >
              <source src={require('../../image/bannerAnimation.mp4')} type="video/mp4" />
            </video> 
        </div>
        <div class='homeheadb'> 
            <div class='col-lg-9 col-md-10 col-11 m-auto my-md-0 my-5 heroText1'  >
              <div class=' d-flex justify-content-md-end' > 
                <div style={{width:'fit-content',position:'relative'}}>
                  <div>
                    <p class='text-white titleText'>Empathy</p>
                  </div>
                  <div>
                    <p class='text-white titleText'>Visualize</p> 
                  </div>
                  <div >
                    <p class='text-white titleText' >Interact</p> 
                  </div> 
                    <div class='text-start'>
                      <button class='button  text-white  my-4 text-start'>Let's Talk</button> 
                    </div>
                </div>
              </div>
            </div> 
            <HeroText2/>
           
         
        </div>
      </div>  

      {/* <div> 
        <VerticalCarousel/>
      </div> */}
      <div>
        <HomeScroll/>
      </div> 
  
    </div>
      <div class='cool'>
        <div class='coolBg'></div>
        <div class='coolText'>
          <div class='col-10 m-auto text-end'>
            <p class='titleText'>Cool</p>
            <p class='titleText'>Things</p>
          </div>
        </div>
      </div>
   

    
      <div style={{backgroundColor:'#F6FFEE'}}>
          <br />
          <div class='col-11 m-auto my-5'>
              <div class='row flex-column-reverse flex-md-row  gap-4 align-items-center justify-content-center'>
                  <div class='col-md-6'>
                      <p class='titleText'>Selected</p>
                  </div>
                  <div class='col-lg-4 col-md-5 col-11 text-start'>
                      <p>Highlighting a range of creative partnerships, revamped digital encounters, and brand makeovers in our showcase.</p>
                  </div>
              </div>
              <div class='text-center'>
                  <p class='titleText' style={{color:'#8AD04D'}}>Projects</p> 
              </div> 
          </div>
          <div>
            <ProjectSlider/>
          </div>
      </div>


 

      <div style={{background:'rgba(0,0,0,0.2'}}>
        <p class='serviceText text-white'>SERVICES</p>
        <div>
          <Service/>
        </div>
        <br /><br />
      </div>

      <div style={{background:'#8AD04D'}}>
        <p class='titleText'>OUR PRODUCT</p>
        <div class='col-11 m-auto row align-items-center'>
          <div class='col-lg-7 col-md-6'>
            <img src={require('../../image/opBg.png')} style={{width:'100%'}} alt="" />
          </div>

          <div class='col-lg-5 col-md-6'>
            <div class='col-sm-11 text-start'> 
             <p class='display-4 fw-bolder'>Plastic Innovation</p>
             <p>Lorem ipsum dolor sit amet, consectetuLorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit amet, consectetur r </p>

             <div class='d-flex align-items-center justify-content-center mt-4' style={{width:'100px', height:'100px', background:'white', borderRadius:'50%'}}>
                <p>Explore</p>
             </div>
             <br />
            </div>
          </div>

        </div>
      </div>

    </div>
  );
};

export default Home;



 