import React, { useEffect, useRef, useState } from 'react'
 import './homeScroll.css'
 import AOS from "aos";
import "aos/dist/aos.css";

const HomeScroll = () => {
  const [isSticky, setIsSticky] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0);
  const [clip, setClip] = useState(0); 
  const [top,setTop] = useState()
  const [bottom,setBottom] = useState() 
 
  const hero1 = useRef(null)  

  useEffect(() => {
    AOS.init({ duration: 1000 });
  },[]); 

  useEffect(() => {
    const getTopHeight = () => {
      const rect1 = hero1.current.getBoundingClientRect();  
      const topHeight = rect1.top + window.scrollY;
      const bottomHeight = rect1.bottom + window.scrollY;
      setTop(topHeight)
      setBottom(bottomHeight) 

    };  
    getTopHeight(); 
    const handleScroll = () => {
      getTopHeight();
    }; 
    window.addEventListener('scroll', handleScroll);  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); 

 

  useEffect(()=>{
    if(scrollPosition > top && scrollPosition <= bottom){
      setIsSticky(true) 
    }else{ 
      setIsSticky(false) 
    }
  },[scrollPosition,top,bottom])

 
  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY || window.pageYOffset;
      setScrollPosition(currentPosition);
      
    }; 
    window.addEventListener('scroll', handleScroll); 
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => { 
    const newSize = 1 + scrollPosition * 0.02;  
    setClip(Math.min(newSize, 200)); 
  }, [scrollPosition]); 


  return (
    <div>  
      <div class='homeHero'> 
        <div class='homeHero1' ref={hero1}>
          <div class='homeHero1S' style={{position:'sticky',top:'0'} }>
            <div class='homeHero1R' style={{position:'relative'}}> 
              <div class='homeHero1R1'> 
              </div>
              <div class='homeHero1A' style={{clipPath:`circle(${clip}% at 50% 50%)` }} >
                <img src={require('../../image/logoI.png')} style={{width:'300px',height:'300px',borderRadius:'50%'}} alt="" />
              </div> 
            </div>
          </div>
        </div>
        

        <div style={{background:'linear-gradient(to right, #8AD04D 10% , transparent 10% , transparent 60% ,#8AD04D 80%)'}}>
          <div class='col-md-10 m-auto'> 
              <img src={require('../../image/weCreate.png')} style={{width:'100%'}} alt="" />
          </div>
        </div>
      </div> 

    </div>

  )
}

export default HomeScroll